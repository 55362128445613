import React from 'react'
import clsx from 'clsx'

export const Loader: React.FC<any> = ({
	loading: isLoading = true,
	className,
	children,
	...props
}) => {
	return (
		<div
			{...props}
			className={clsx('loader', className, {
				'is:animating': isLoading,
			})}
		>
			<span className="loader__label">{children}</span>
		</div>
	)
}

export const ViewLoader: React.FC<any> = ({ ...props }) => {
	return (
		<Loader
			type="spinner"
			{...props}
			style={{
				position: 'fixed',
				left: '50%',
				right: '50%',
			}}
		/>
	)
}
