import React, { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import 'source-map-support/browser-source-map-support'
import { Request } from '@sm/server/types'
import { ModalProvider } from '@sm/client/components'
import { getConfig } from '@sm/client/lib'

import '../less/index.less'
import 'react-quill/dist/quill.snow.css'

interface ShowmanagerProps extends AppProps {
	req: Request
}

const config = getConfig()
const isBrowser = typeof window !== 'undefined'
const isProduction = config.env === 'production'
// const isProduction = config.env === 'production'
// console.debug('showmanager', config)
if (isBrowser && isProduction) {
	// console.info('Enable production sourcemaps')
	// @ts-ignore
	window.sourceMapSupport.install()
}

function App(props: ShowmanagerProps) {
	const { Component, pageProps } = props

	return (
		<>
			<Head>
				{/* <title>{title}</title> */}
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="mobile-web-app-capable" content="yes" />
				<link
					rel="icon"
					type="image/png"
					sizes="128x128"
					href="/favicon-128x128.png"
				/>
			</Head>
			<ModalProvider>
				<Component {...pageProps} />
			</ModalProvider>
		</>
	)
}

export default App
