import getNextConfig from 'next/config'
import { get } from 'lodash'
interface RuntimeConfig {
	url: string
	version: string
	env: 'production' | 'development' | 'test'
	debug: boolean
}

type ConfigKey = keyof RuntimeConfig

export const getConfigValue = (key: ConfigKey): any => {
	const config = getConfig()
	// console.debug('get', path, config)
	return get(config, key)
}

export const getConfig = (): RuntimeConfig => {
	const nextConfig = getNextConfig()

	const config = {
		...(nextConfig?.publicRuntimeConfig ?? {}),
		...(nextConfig?.serverRuntimeConfig ?? {}),
	}

	return config
}