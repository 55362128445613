import React, { useCallback, FunctionComponent } from 'react'
import clsx from 'clsx'
import { Icon, Loader } from '@sm/client/components'

interface ButtonProps {
	className?: string
	style?: any
	icon?: string
	label?: string
	title?: string
	tabIndex?: number
	type?: 'submit' | 'reset' | 'button'
	primary?: boolean
	naked?: boolean
	loading?: boolean
	disabled?: boolean
	children?: React.ReactNode
	onPress?: (e: React.MouseEvent) => void
	onClick?: (e: React.MouseEvent) => void
}

export const Button: React.FC<ButtonProps> = ({
	className,
	style,
	icon,
	label,
	title,
	tabIndex,
	children,
	onPress,
	onClick,
	type = 'button',
	primary: isPrimary,
	naked: isNaked,
	loading: isLoading,
	disabled: isDisabled,
	// ...otherProps
}) => {
	// const isLoading = true
	const content = children ?? label
	const isIconOnly = !content && !!icon
	const shouldRenderIcon = !isLoading && icon
	const isSubmitButton = type === 'submit'

	const clickHandler = useCallback(
		(e) => {
			e.preventDefault()
			// fall back to onPress
			const handler = onClick ?? onPress
			if (handler) {
				// let handler override event handler return
				const result = handler(e)
				if (result !== undefined) {
					return result
				}
			}
			return false
		},
		[onClick, onPress],
	)

	return (
		<button
			type={type}
			title={title}
			style={style}
			tabIndex={tabIndex}
			disabled={isDisabled}
			className={clsx(
				'button',
				{
					[`icon:${icon}`]: shouldRenderIcon,
					'button:primary': isPrimary,
					'button:naked': isNaked,
					'is:loading': isLoading,
					'is:disabled': isDisabled,
					// 'button:disabled': isDisabled,
					'has:iconOnly': isIconOnly,
				},
				className,
			)}
			onClick={isSubmitButton ? undefined : clickHandler}
			// {...otherProps}
		>
			{isLoading && (
				<Loader
					className={clsx('spinner', {
						'spinner--overlay': isIconOnly,
						'spinner--inline': !isIconOnly,
					})}
				/>
			)}
			<span>{content}</span>
		</button>
	)
}
