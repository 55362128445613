import { isObject, isString } from 'lodash'

export const writeToClipboard = (data: any) => {
	console.debug('[clipboard] write', data)
	if (navigator.clipboard) {
		if (isString(data)) {
			navigator.clipboard.writeText(data)
		}
		if (isObject(data)) {
			navigator.clipboard.writeText(JSON.stringify(data))
		}
	}
}
