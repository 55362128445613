import clsx from 'clsx'
import { isObject, omit } from 'lodash'
import { useMemo } from 'react'
// import { parse as parseStacktrace, StackFrame } from 'stacktrace-parser'

const renderContextValue = (value) => {
	if (isObject(value)) {
		return JSON.stringify(value)
	}

	return value.toString()
	// value
}

export const ErrorReport: React.FC<any> = ({
	className,
	message,
	stack,
	// error,
	context = {},
}) => {

	// const stack = useMemo(() => {
	// 	return parseStacktrace(error.stack)
	// }, [error])

	const displayContext = useMemo(() => {
		return omit(context, [
			'userAgent', 'platform', 'screen', 'time', 'variables', 'graphql',
		])
	}, [context])

	return (
		<div className={clsx('errorReport', className)}>
			<div className="errorReport__context">
				{Boolean(message) && (
					<div className="errorReport__contextItem">
						<div className="errorReport__contextLabel">message</div>
						<div className="errorReport__contextData">{message}</div>
					</div>
				)}
				{Object.entries(displayContext).map(([key, value]) => {
					return (
						<div key={key} className="errorReport__contextItem">
							<div className="errorReport__contextLabel">{key}</div>
							<div className="errorReport__contextData">
								{renderContextValue(value)}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
