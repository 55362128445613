import {
	useRef,
	createContext,
	useMemo,
	useState,
	useContext,
	useCallback,
	useEffect,
} from 'react'
// import * as ReactModal from 'react-modal'
import ModalOverlay, { RenderModalBackdropProps } from 'react-overlays/Modal'
import { useRouter } from 'next/router'
import ModalManager, { ContainerState } from 'react-overlays/ModalManager'
import addClass from 'dom-helpers/addClass'
import removeClass from 'dom-helpers/removeClass'
import css from 'dom-helpers/css'
import { isDate, omit } from 'lodash'
// import { RenderModalBackdropProps } from 'react-overlays/Modal'
import clsx from 'clsx'
import { getConfig, writeToClipboard, createErrorReportContext } from '@sm/client/lib'
import { Button, ErrorReport } from '@sm/client/components'
import { Renderable } from '@sm/client/types'
import { parse as parseStacktrace } from 'stacktrace-parser'
// import { any } from 'sequelize/types/lib/operators'
// ReactModal.default.setAppElement('#__next')

// ModalOverlay.se

export interface ModalProps {
	isOpen?: boolean
	className?: string
	children
	onClose?: () => void
}

interface PromptOptions {
	title?: string
	subtitle?: string
	content?: any
	actions?: {
		[name: string]: PromptAction | boolean
	}
}

interface PromptAction {
	label?: string
	handler?: () => void
	className?: string
	icon?: string
}

class CustomModalManager extends ModalManager {
	setContainerStyle(containerState: ContainerState, container: HTMLElement) {
		// const style: Partial<CSSStyleDeclaration> = { overflow: 'hidden' }

		// we are only interested in the actual `style` here
		// because we will override it
		// containerState.style = {
		// 	overflow: container.style.overflow,
		// 	// paddingRight: container.style.paddingRight,
		// }

		if (containerState.overflowing) {
			// use computed style, here to get the real padding
			// to add our scrollbar width
			// style.paddingRight = `${
			// 	parseInt(css(container, 'paddingRight') || '0', 10) + this.scrollbarSize
			// }px`
		}
		// console.debug('add', document.body, style)
		// css(document.body, { overflow: 'hidden' })
		addClass(document.body, 'has:modal')
		// css(container, style as any)
	}
	removeContainerStyle(containerState: ContainerState, container: HTMLElement) {
		// Object.assign(container.style, containerState.style)
		removeClass(document.body, 'has:modal')
		// Object.assign(document.body.style, containerState.style)
	}
}

let manager: CustomModalManager

const getManager = () => {
	if (!manager) manager = new CustomModalManager()
	return manager
}

const ModalBackdrop: React.FC<RenderModalBackdropProps> = (props) => {
	// console.debug(props)
	return <div className="modalBackdrop" {...props} />
}

export const Modal: React.FC<ModalProps> = ({
	children,
	isOpen,
	onClose,
	className,
}) => {
	// const { ref } = useContext(ModalContext)
	const { containerRef } = useContext(ModalContext)
	return (
		<ModalOverlay
			className={clsx('modal', className)}
			container={containerRef}
			show={isOpen}
			onHide={onClose}
			manager={getManager()}
			renderBackdrop={ModalBackdrop}
			onBackdropClick={onClose}
		>
			<>{children ?? ''}</>
		</ModalOverlay>
	)
}

export const ModalContext = createContext<any>({
	containerRef: null,
	prompt: null,
})

export const useModal = () => {
	const { containerRef, prompt, error } = useContext(ModalContext)
	return { containerRef, prompt, error }
}

const config = getConfig()
const isProduction = config.env === 'production'
const isBrowser = typeof window !== 'undefined'

export const ModalProvider: React.FC<any> = ({ children }) => {
	const containerRef = useRef<any>()
	const [promptState, setPromptState] = useState<any>({ isOpen: false })
	const router = useRouter()

	const showPrompt = useCallback((promptOpts) => {
		setPromptState({
			isOpen: true,
			className: 'promptModal',
			...promptOpts,
		})
	}, [])

	const showErrorPrompt = useCallback(({ error, context: extraContext, ...promptOpts }) => {
		// console.debug('setting errormodal state')

		const stack = parseStacktrace(error.stack)
		const context: any = {
			...(createErrorReportContext(error, {
				path: router.asPath ?? router.pathname,
				query: router.query ?? undefined,
			})),
			...extraContext,
		}

		setPromptState({
			isOpen: true,
			className: 'errorReportModal',
			content: ErrorReport,
			contentProps: {
				className: 'modal__section',
				message: error.toString(),
				stack,
				context,
			},
			actions: {
				copy: {
					// className: 'button:primary',
					label: 'Kopieren',
					icon: 'copy',
					handler: () => {
						writeToClipboard({
							message: error.message,
							context: context,
							stack,
						})
					},
				},
				continue: {
					className: 'button:primary',
					label: 'Weiter',
					handler: () => {
						setPromptState({ isOpen: false })
						// router.back()
						// onPromptConfirm()
					},
				},
				// reload: {
				// 	className: 'button:primary',
				// 	label: 'Neu laden',
				// 	icon: 'refresh',
				// 	handler: () => {
				// 		router.reload()
				// 	},
				// },
			},
			...promptOpts,
		})
	}, [])

	useEffect(() => {
		if (isBrowser) {

			window.addEventListener('unhandledrejection', function(e) { 
				// console.debug('unhandled', e)
				if (e.reason) {
					showErrorPrompt({
						title: 'Client Error',
						subtitle: 'Ein unkritischer Fehler ist aufgetreten.',
						error: e.reason,
					})
				}
				
			})
			window.onerror = (msg, url, lineNo, columnNo, error) => {
				// console.debug('onerror', error, msg, url)
				if (error) {
					showErrorPrompt({
						title: 'Client Error',
						subtitle: 'Ein unkritischer Fehler ist aufgetreten.',
						error,
					})
				}
				return false
			}
		}

		return () => {
			// TODO 
		}
	})

	const promptActions = useMemo(() => {
		if (promptState?.actions) {
			return promptState.actions
		}
		return {
			confirm: true,
		}
	}, [promptState.actions])

	const onPromptCancel = useCallback(() => {
		if (promptActions.cancel?.handler) {
			promptActions.cancel?.handler()
		}
		setPromptState({ isOpen: false })
	}, [promptActions])

	const onPromptConfirm = useCallback(() => {
		if (promptActions.confirm?.handler) {
			const confirmHandler = promptActions.confirm?.handler
			setPromptState({ ...promptState, isProcessing: true })

			Promise.resolve(confirmHandler())
				.then(() => {
					setPromptState({ isOpen: false })
				})
				.catch((err) => {
					console.error(err)
					setPromptState({ ...promptState, isProcessing: false })
				})
		} else {
			setPromptState({ isOpen: false })
		}
	}, [promptActions])

	const promptActionChildren = useMemo(() => {
		// console.debug('proimptOp', promptOptions)
		const children: any = []
		const { actions, isProcessing, isOpen } = promptState

		if (!actions) {
			return children
		}

		const extraActions = omit(actions, ['confirm', 'cancel'])

		Object.entries<PromptAction>(extraActions).forEach(([name, props]) => {
			children.push(
				<Button
					key={name}
					className={props.className}
					onPress={props.handler}
					icon={props.icon}
				>
					{props.label}
				</Button>,
			)
		})

		if (actions.cancel) {
			const cancelProps = {
				className: actions.cancel?.className ?? 'link button:naked',
				children: actions.cancel?.label ?? 'Abbrechen',
				icon: actions.cancel?.icon,
				onPress: onPromptCancel,
			}
			children.push(<Button key={'cancel'} {...cancelProps} />)
		}

		if (actions.confirm) {
			const confirmProps = {
				loading: isProcessing,
				className: actions.confirm?.className ?? 'button:primary',
				children: actions.confirm?.label ?? 'OK',
				// icon: actions.confirm?.icon ?? 'accept',
				icon: actions.confirm?.icon ?? '',
				onPress: onPromptConfirm,
			}
			children.push(<Button key={'confirm'} {...confirmProps} />)
		}

		return children
	}, [promptState])

	const ContentComponent = promptState.content ?? null

	return (
		<ModalContext.Provider value={{ containerRef, prompt: showPrompt, error: showErrorPrompt }}>
			{promptState.isOpen && (
				<Modal
					className={promptState.className}
					isOpen={promptState.isOpen}
					onClose={() => {
						if (promptState.closeable !== false) {
							setPromptState({ isOpen: false })
						}

					}}
				>
					<div className="modal__content">
						<div className="modal__header">
							{Boolean(promptState.title) && (
								<h1 className="modal__title">{promptState.title}</h1>
							)}
							{Boolean(promptState.subtitle) && (
								<span className="modal__subtitle">{promptState.subtitle}</span>
							)}
						</div>
						{Boolean(ContentComponent) && (
							<ContentComponent {...promptState.contentProps} />
						)}
						<div className="modal__section modal__actions">
							{promptActionChildren}
						</div>
					</div>
				</Modal>
			)}
			{children}
		</ModalContext.Provider>
	)
}
