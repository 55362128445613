import nextRouter from 'next/router'

export const createErrorReportContext = (error, extraContext = {}) => {

	const context: any = {
		...extraContext,
		time: new Date(),
		...createUserAgentReportContext(),
	}

	// console.debug(Object.keys(error))
	// console.debug(error.graphQLErrors, error.clientErrors, error.networkError, error.extraInfo)

	const graphqlErrors = error.graphQLErrors ?? []
	if (graphqlErrors.length) {
		context.graphql = {
			message: graphqlErrors[0].message,
			path: graphqlErrors[0].path,
			...graphqlErrors[0].extensions,
		}
	}

	return context

}

export const createUserAgentReportContext = () => {
	if (typeof window !== 'undefined') {
		return {
			userAgent: navigator.userAgent,
			// @ts-ignore
			platform: navigator.platform ?? navigator.userAgentData.platform,
			screen: {
				height: window.screen.height,
				width: window.screen.width,
				dpr: window.devicePixelRatio,
			},
		}
	}

	return {}
}
